var exports = {};
var g = Object.defineProperty;
var j = Object.getOwnPropertyDescriptor;
var L = Object.getOwnPropertyNames;
var V = Object.prototype.hasOwnProperty;
var Y = (e, r) => {
    for (var t in r) g(e, t, {
      get: r[t],
      enumerable: !0
    });
  },
  K = (e, r, t, o) => {
    if (r && typeof r == "object" || typeof r == "function") for (let s of L(r)) !V.call(e, s) && s !== t && g(e, s, {
      get: () => r[s],
      enumerable: !(o = j(r, s)) || o.enumerable
    });
    return e;
  };
var $ = e => K(g({}, "__esModule", {
  value: !0
}), e);
var h = (e, r, t) => new Promise((o, s) => {
  var i = a => {
      try {
        l(t.next(a));
      } catch (m) {
        s(m);
      }
    },
    c = a => {
      try {
        l(t.throw(a));
      } catch (m) {
        s(m);
      }
    },
    l = a => a.done ? o(a.value) : Promise.resolve(a.value).then(i, c);
  l((t = t.apply(e, r)).next());
});
var W = {};
Y(W, {
  SubmissionError: () => p,
  appendExtraData: () => E,
  createClient: () => F,
  getDefaultClient: () => U,
  isSubmissionError: () => A
});
exports = $(W);
var u = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
  J = /^(?:[A-Za-z\d+\/]{4})*?(?:[A-Za-z\d+\/]{2}(?:==)?|[A-Za-z\d+\/]{3}=?)?$/;
function I(e) {
  e = String(e);
  for (var r, t, o, s, i = "", c = 0, l = e.length % 3; c < e.length;) {
    if ((t = e.charCodeAt(c++)) > 255 || (o = e.charCodeAt(c++)) > 255 || (s = e.charCodeAt(c++)) > 255) throw new TypeError("Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range.");
    r = t << 16 | o << 8 | s, i += u.charAt(r >> 18 & 63) + u.charAt(r >> 12 & 63) + u.charAt(r >> 6 & 63) + u.charAt(r & 63);
  }
  return l ? i.slice(0, l - 3) + "===".substring(l) : i;
}
function O(e) {
  if (e = String(e).replace(/[\t\n\f\r ]+/g, ""), !J.test(e)) throw new TypeError("Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded.");
  e += "==".slice(2 - (e.length & 3));
  for (var r, t = "", o, s, i = 0; i < e.length;) r = u.indexOf(e.charAt(i++)) << 18 | u.indexOf(e.charAt(i++)) << 12 | (o = u.indexOf(e.charAt(i++))) << 6 | (s = u.indexOf(e.charAt(i++))), t += o === 64 ? String.fromCharCode(r >> 16 & 255) : s === 64 ? String.fromCharCode(r >> 16 & 255, r >> 8 & 255) : String.fromCharCode(r >> 16 & 255, r >> 8 & 255, r & 255);
  return t;
}
var G = () => navigator.webdriver || !!document.documentElement.getAttribute(O("d2ViZHJpdmVy")) || !!window.callPhantom || !!window._phantom,
  y = class {
    constructor() {
      this.loadedAt = Date.now(), this.webdriver = G();
    }
    data() {
      return {
        loadedAt: this.loadedAt,
        webdriver: this.webdriver
      };
    }
  };
var S = class {
  constructor(r) {
    this.kind = "success";
    this.next = r.next;
  }
};
function w(e) {
  return "next" in e && typeof e.next == "string";
}
var b = class {
  constructor(r, t) {
    this.paymentIntentClientSecret = r;
    this.resubmitKey = t;
    this.kind = "stripePluginPending";
  }
};
function _(e) {
  if ("stripe" in e && "resubmitKey" in e && typeof e.resubmitKey == "string") {
    let {
      stripe: r
    } = e;
    return typeof r == "object" && r != null && "paymentIntentClientSecret" in r && typeof r.paymentIntentClientSecret == "string";
  }
  return !1;
}
function A(e) {
  return e.kind === "error";
}
var p = class {
  constructor(...r) {
    this.kind = "error";
    this.formErrors = [];
    this.fieldErrors = new Map();
    var t;
    for (let o of r) {
      if (!o.field) {
        this.formErrors.push({
          code: o.code && z(o.code) ? o.code : "UNSPECIFIED",
          message: o.message
        });
        continue;
      }
      let s = (t = this.fieldErrors.get(o.field)) != null ? t : [];
      s.push({
        code: o.code && Q(o.code) ? o.code : "UNSPECIFIED",
        message: o.message
      }), this.fieldErrors.set(o.field, s);
    }
  }
  getFormErrors() {
    return [...this.formErrors];
  }
  getFieldErrors(r) {
    var t;
    return (t = this.fieldErrors.get(r)) != null ? t : [];
  }
  getAllFieldErrors() {
    return Array.from(this.fieldErrors);
  }
};
function z(e) {
  return e in B;
}
var B = {
  BLOCKED: "BLOCKED",
  EMPTY: "EMPTY",
  FILES_TOO_BIG: "FILES_TOO_BIG",
  FORM_NOT_FOUND: "FORM_NOT_FOUND",
  INACTIVE: "INACTIVE",
  NO_FILE_UPLOADS: "NO_FILE_UPLOADS",
  PROJECT_NOT_FOUND: "PROJECT_NOT_FOUND",
  TOO_MANY_FILES: "TOO_MANY_FILES"
};
function Q(e) {
  return e in Z;
}
var Z = {
  REQUIRED_FIELD_EMPTY: "REQUIRED_FIELD_EMPTY",
  REQUIRED_FIELD_MISSING: "REQUIRED_FIELD_MISSING",
  STRIPE_CLIENT_ERROR: "STRIPE_CLIENT_ERROR",
  STRIPE_SCA_ERROR: "STRIPE_SCA_ERROR",
  TYPE_EMAIL: "TYPE_EMAIL",
  TYPE_NUMERIC: "TYPE_NUMERIC",
  TYPE_TEXT: "TYPE_TEXT"
};
function P(e) {
  return "errors" in e && Array.isArray(e.errors) && e.errors.every(r => typeof r.message == "string") || "error" in e && typeof e.error == "string";
}
var D = "3.0.1";
var v = e => I(JSON.stringify(e)),
  N = e => {
    let r = `@formspree/core@${D}`;
    return e ? `${e} ${r}` : r;
  };
function E(e, r, t) {
  e instanceof FormData ? e.append(r, t) : e[r] = t;
}
function M(e) {
  return e !== null && typeof e == "object";
}
var R = class {
  constructor(r = {}) {
    this.project = r.project, this.stripe = r.stripe, typeof window != "undefined" && (this.session = new y());
  }
  submitForm(s, i) {
    return h(this, arguments, function* (r, t, o = {}) {
      let c = o.endpoint || "https://formspree.io",
        l = this.project ? `${c}/p/${this.project}/f/${r}` : `${c}/f/${r}`,
        a = {
          Accept: "application/json",
          "Formspree-Client": N(o.clientName)
        };
      this.session && (a["Formspree-Session-Data"] = v(this.session.data())), t instanceof FormData || (a["Content-Type"] = "application/json");
      function m(f) {
        return h(this, null, function* () {
          try {
            let n = yield (yield fetch(l, {
              method: "POST",
              mode: "cors",
              body: f instanceof FormData ? f : JSON.stringify(f),
              headers: a
            })).json();
            if (M(n)) {
              if (P(n)) return Array.isArray(n.errors) ? new p(...n.errors) : new p({
                message: n.error
              });
              if (_(n)) return new b(n.stripe.paymentIntentClientSecret, n.resubmitKey);
              if (w(n)) return new S({
                next: n.next
              });
            }
            return new p({
              message: "Unexpected response format"
            });
          } catch (d) {
            let n = d instanceof Error ? d.message : `Unknown error while posting to Formspree: ${JSON.stringify(d)}`;
            return new p({
              message: n
            });
          }
        });
      }
      if (this.stripe && o.createPaymentMethod) {
        let f = yield o.createPaymentMethod();
        if (f.error) return new p({
          code: "STRIPE_CLIENT_ERROR",
          field: "paymentMethod",
          message: "Error creating payment method"
        });
        E(t, "paymentMethod", f.paymentMethod.id);
        let d = yield m(t);
        if (d.kind === "error") return d;
        if (d.kind === "stripePluginPending") {
          let n = yield this.stripe.handleCardAction(d.paymentIntentClientSecret);
          if (n.error) return new p({
            code: "STRIPE_CLIENT_ERROR",
            field: "paymentMethod",
            message: "Stripe SCA error"
          });
          t instanceof FormData ? t.delete("paymentMethod") : delete t.paymentMethod, E(t, "paymentIntent", n.paymentIntent.id), E(t, "resubmitKey", d.resubmitKey);
          let x = yield m(t);
          return k(x), x;
        }
        return d;
      }
      let T = yield m(t);
      return k(T), T;
    });
  }
};
function k(e) {
  let {
    kind: r
  } = e;
  if (r !== "success" && r !== "error") throw new Error(`Unexpected submission result (kind: ${r})`);
}
var F = e => new R(e),
  U = () => (C || (C = F()), C),
  C;
0 && (exports = {
  SubmissionError,
  appendExtraData,
  createClient,
  getDefaultClient,
  isSubmissionError
});
export default exports;
const _SubmissionError = exports.SubmissionError,
  _appendExtraData = exports.appendExtraData,
  _createClient = exports.createClient,
  _getDefaultClient = exports.getDefaultClient,
  _isSubmissionError = exports.isSubmissionError;
export { _SubmissionError as SubmissionError, _appendExtraData as appendExtraData, _createClient as createClient, _getDefaultClient as getDefaultClient, _isSubmissionError as isSubmissionError };